@import "@/styles/globals.scss";
@import '../../styles/defaults/css-properties';

// Variables
$green: #55ba48;
$dark-green: #409036;
$blue: #0293d6;
$pink: #ee4c9c;
$orange: #f9a61d;

$light-gray: #e2e8f0;
$very-light-gray: #f5f5f5;
$medium-gray: #424242;
$dark-gray: #212b36;
$shadow-gray: #d1d2d3;
$hover-gray: #ededed;

$link-color: $primary-color;
$link-hover-color: $dark-green;

$cart-divider-color: $light-gray;

$heading-color: $dark-gray;

$box-shadow-dimensions: 0px 2px 0px;

$datepicker-icon-color: $medium-gray;
$datepicker-icon-color-hover: $medium-gray;
$datepicker-icon-color-active: white;

$steppers-3-button-background-hover-color: $hover-gray;

$divider: 1px solid $light-gray;

$selectable-height: 48px;

$transition-properties: cubic-bezier(0.4, 0, 0.2, 1) 0.3s;

$transition: background-color $transition-properties, color $transition-properties, opacity $transition-properties;

$cancel-free-tickets-border-color: $light-gray;
$cancel-free-tickets-background-color: #eff0f5;

$radio-button-background-color: $very-light-gray;
$radio-button-border-color: $shadow-gray;
$radio-button-checked-background-color: $very-light-gray;
$radio-button-checked-border-color: $shadow-gray;
$radio-button-selected-color: black;

$cart-price-background-color: $very-light-gray;

// Defaults
@import '../../styles/defaults/index';
@import '../../styles/helpers/index';

// Placeholders
%button {
  padding: 14px 30px;
  border-radius: 4px;
  font-family: $secondary-font;
  font-size: 20px;
  transition: $transition;
}

%primary-button {
  background-color: $green;
  box-shadow: $box-shadow-dimensions $dark-green;
}

%primary-button-hover {
  background-color: $dark-green;
}

%secondary-button {
  color: $medium-gray;
  box-shadow: $box-shadow-dimensions $shadow-gray;
  background-color: $very-light-gray;
}

%secondary-button-hover {
  background-color: $hover-gray;
  color: $medium-gray;
}

%secondary-button-active {
  background-color: $medium-gray;
  color: white;
  box-shadow: $box-shadow-dimensions $medium-gray;
}

%h1 {
  font-size: 36px;
  margin-bottom: 32px;

  @include large {
    font-size: 48px;
  }
}

%h2 {
  font-size: 28px;
}

%h3 {
  line-height: 22px;
  margin-bottom: 10px;
}

%label {
  font-family: $primary-font;
  font-weight: bold;
  color: $dark-gray;
}

%input {
  border: 1px solid $shadow-gray;
  background-color: $very-light-gray;
}

%back-button {
  font-family: $secondary-font;
  color: $primary-color;
  font-size: 20px;
  transition: $transition;

  &:hover,
  &:focus {
    color: $dark-green;
  }

  &:before {
    width: 20px;
  }
}

%actions {
  padding-top: 40px;

  @include large {
    padding-top: 48px;
  }
}

// Base elements
@import 'page';
@import 'elements';
@import 'links-and-buttons';
@import 'form-inputs';
@import 'modals';
@import 'messages';
@import 'badges';

// Components
@import '_header';
@import '_footer';
@import 'filters';
@import 'actions';
@import 'cart';
@import 'selection';
@import 'steppers-3';
@import 'calendar';
@import 'identity-form';
@import 'members-menu';
@import 'legend';

// Routes
@import 'checkout';
@import 'completed';
@import 'event-list';
@import 'buy-membership';
@import 'gift-of-membership';
@import 'forgot-membership';
@import 'event';
@import 'resend-tickets';
@import 'manage-order';
@import 'home';
@import 'my-account';

// Theme
@import 'sass/styles';
@import 'sass/header';
@import 'sass/footer';

a {
  transition: $transition;
}

.event-listing {
  article.event.event-listing-item {
    @include mediumAndLarge {
      margin-bottom: 28px;
      padding-bottom: 28px;
    }

    img {
      border-radius: 6px;

      @include mobileExperience {
        margin-bottom: 16px;
      }
    }

    a.title-link h2 {
      margin-bottom: 8px;
      margin-top: 4px;

      @include mobileExperience {
        margin-top: 0;
      }
    }

    .subtitle {
      margin-bottom: 6px;
    }

    div.button {
      margin-top: 13px;
    }
  }
}

section.cart {
  border-radius: 6px;
  overflow: hidden;

  > h2 {
    background-color: $blue;
    color: white;
  }

  > .content {
    .empty-cart-message {
      border-radius: 0 0 6px 6px;
      border: $divider;
      border-top: none;
    }

    > div {
      border-left: $divider;
      border-right: $divider;
    }

    .ticket-date {
      font-family: $primary-font;
      margin-top: 8px;
    }

    .add-promo-button {
      &:hover,
      &:focus {
        color: $dark-green;
      }
    }

    .ticket-type {
      margin-bottom: 4px;
      line-height: 24px;
    }

    .ticket-wrapper .remove-cart-item-overlay .actions button {
      padding-top: 13px;
      padding-bottom: 13px;
    }
  }

  .total-prices {
    background-color: #f8f8f8;
  }

  button.primary.purchase {
    font-size: 26px;
    padding: 25px 30px;
    border-radius: 0;
  }
}

.mobile-footer {
  .more-events-button {
    box-shadow: none;
  }
}

.checkout-route {
  .purchaser-details,
  .upsell,
  .terms-conditions {
    margin-bottom: 48px;
  }

  .apply-gift-cards {
    margin-bottom: 28px;
  }

  .payment {
    > h2 {
      margin-bottom: 28px;
    }
  }

  .apply-gift-cards {
    .add-card-form {
      button.apply-card {
        box-shadow: none;
        padding: 15px 0;
      }
    }
  }
}

.stepper {
  > button,
  .count {
    border: none;
  }

  .count {
    border-radius: 0;
    background-color: $shadow-gray;
    box-shadow: $box-shadow-dimensions $shadow-gray;
    font-family: $secondary-font;
    font-size: 20px;
  }

  &.active {
    .count {
      background-color: $medium-gray;
      color: white;
      box-shadow: 0 2px 0 $medium-gray;
    }
  }

  > button {
    border-radius: 4px;
    @extend %secondary-button;
    line-height: 0;

    svg {
      margin-top: 3px;
    }
  }
}

.selection {
  .select-tickets {
    .ticket-type.fixed-price {
      .price-wrapper {
        font-weight: normal;
      }
    }

    .price-container {
      margin-top: 8px;
    }
  }

  .ticket-group {
    margin-bottom: 40px;
  }

  .select-date-wrapper,
  .select-session-wrapper,
  .select-tickets {
    margin-bottom: 48px;
  }

  .tickets {
    margin-top: 21px;
  }
}

.content-detail-page .ticket-group-description-wrapper {
  margin-bottom: 21px;
}

input[type='checkbox'] {
  border: 1px solid $shadow-gray;
  background-color: $very-light-gray;
  border-radius: 0;
  width: 24px;
  height: 24px;

  &:checked {
    border: 1px solid $shadow-gray;
    background-color: $very-light-gray;

    &:before {
      border-color: $dark-gray;
      left: 8px;
      top: 3px;
    }
  }
}

.joint-input-button {
  > .wrapper {
    button.apply-promo {
      box-shadow: none;
      font-size: 18px;
    }
  }
}

.filters {
  margin-bottom: 48px;
}

.purchaser-details {
  > h2 {
    margin-top: 0;
  }
}

.modal .modal-window {
  border-radius: 6px;
}

.complete {
  .body-inner .site-content-inner {
    padding-top: 0;
  }

  .main {
    border-radius: 6px;

    @include small {
      padding: 0 28px;
    }

    h1 {
      font-size: 36px;
      margin: 12px 0 32px;

      @include large {
        font-size: 28px;
      }

      span {
        color: $dark-gray;
        font-size: 48px;
      }
    }

    code.order-number {
      color: $dark-gray;
    }
  }

  .complete-message p {
    margin-top: 32px;
  }
}

.content-detail-page {
  .event > figure {
    border-radius: 6px;
    overflow: hidden;
  }
}

.donation,
.gift-cards {
  .ticket-group {
    .radio-item {
      .label {
        font-family: $secondary-font;
        font-size: 20px;
        margin-bottom: 4px;
      }
    }
  }
}

.order-form {
  button.cancel-order {
    @extend %secondary-button;
  }
}

@include small {
  .body-inner .site-content-inner {
    padding-top: 32px;
  }

  .body-inner .has-sidebar .aside {
    margin: 40px 0;
  }

  .event-listing article.event {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}

label.form-input {
  &.text,
  &.email,
  &.tel,
  &.boolean,
  &.select,
  &.password,
  &.number {
    margin-bottom: 24px;

    @include large {
      margin-bottom: 32px;
    }
  }
}

.upsell label.radio-item.form-input.selectable.ticket-type {
  padding: 14px;
}

.radio-items .radio-item {
  @include large {
    margin-bottom: 32px;
  }
}

.gift-cards {
  .ticket-group > h2 {
    margin-bottom: 24px;
  }

  .radio-group.ticket-types {
    .ticket-type {
      margin-bottom: 24px;
    }
  }
}

div.message {
  border-radius: 4px;
}

.selected-date-time,
.selectable-button {
  border-radius: 4px;
  line-height: 1.2;
  font-size: 18px;
  font-family: $secondary-font;
  color: $medium-gray;
}

.select-available-session,
.date-selector {
  .selectable-button {
    border: none;
    background-color: $very-light-gray;
    transition: $transition;

    &.available {
      box-shadow: $box-shadow-dimensions $shadow-gray;

      @include hover {
        background-color: $hover-gray;
        color: $medium-gray;
      }
    }
  }
}

.selected-date-time {
  .change-btn .btn {
    font-size: 16px;
  }
}

.section-header {
  h3 {
    font-family: $primary-font;
    font-weight: bold;
  }

  p {
    line-height: 1.1;
    margin-bottom: 0;
    margin-top: 8px;
  }
}

.site-footer {
  background-color: $orange;
  padding: 18px;
  font-size: 14px;

  @include small {
    text-align: center;
  }

  p {
    margin: 0;
  }
}
